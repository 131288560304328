<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">邀请好友赢奖励</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display:flex;flex-direction: column;align-items:start">
          <div class="searchbox">
            <div title="活动名称" class="searchboxItem ci-full">
              <span class="itemLabel">活动名称:</span>
              <el-input
                v-model="activityName"
                type="text"
                size="small"
                placeholder="请输入活动名称"
                clearable
              />
            </div>
            <div title="活动日期" class="searchboxItem ci-full">
              <span class="itemLabel">活动日期:</span>
              <el-date-picker
                v-model="activityDate"
                size="small"
                 type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
           <div class="searchbox" style="margin-top:15px">
            <div title="活动状态" class="searchboxItem ci-full">
              <span class="itemLabel">活动状态:</span>
              <el-select
                v-model="activityStatus"
                clearable
                placeholder="请选择活动状态"
                size="small"
              >
                <el-option
                  v-for="item in ActivityStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel">是否上架:</span>
              <el-select
                v-model="isShelf"
                clearable
                placeholder="请选择是否上架"
                size="small"
              >
                <el-option
                  v-for="item in IsShelfList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="CreateActivity()"
              >创建活动</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="活动名称"
                fixed
                align="left"
                prop="invitedName"
                show-overflow-tooltip
                width="260"
              />
              <el-table-column
                label="活动日期"
                align="left"
                prop="startEndTime"
                show-overflow-tooltip
                width="350"
              />
              <el-table-column
                label="活动范围"
                align="left"
                prop="invitedAreaText"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeText"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="邀请者奖励积分"
                align="left"
                prop="inviterScoreDesc"
                show-overflow-tooltip
                width="250"
              />
              <el-table-column
                label="被邀请者奖励积分"
                align="left"
                prop="inviteeScoreDesc"
                show-overflow-tooltip
                width="250"
              />
              <el-table-column
                label="累计参加人数"
                align="left"
                prop="attendNum"
                show-overflow-tooltip
                width="100"
              />
              <el-table-column
                label="活动状态"
                align="left"
                prop="invitedState"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ $setDictionary("INVITEDSTATE", scope.row.invitedState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否上架"
                align="left"
                prop="validState"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-switch
                    :width="20"
                    v-model="scope.row.validState"
                    :active-value="true"
                    :inactive-value="false"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        handleStu(val, scope.row.invitedId,scope.$index);
                      }
                    "
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                prop="createTime"
                width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                prop="mobile"
                fixed="right"
                show-overflow-tooltip
                width="150"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="ActivityDetails(scope.row.invitedId)"
                    >活动明细</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row.invitedId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    :disabled="!scope.row.deleteState"
                    @click="handleDelete(scope.row.invitedId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "InviteFriendsToWinAwardsList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activityName: "", //活动名称
      activityDate: [], //活动日期
      activityStatus: "", //活动状态
      isShelf: "", //是否上架
      ActivityStatusList: [],
      IsShelfList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
    };
  },
  created() {
    this.getinvitedstateList();
  },
  computed: {},
  methods: {
    // 获取活动状态
    getinvitedstateList() {
      const invitedstatelist = this.$setDictionary("INVITEDSTATE", "list");
      const list = [];
      for (const key in invitedstatelist) {
        list.push({
          value: key,
          label: invitedstatelist[key],
        });
      }
      this.ActivityStatusList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        validState: this.isShelf,
      };
      if (this.activityName) {
        params.invitedName = this.activityName;
      }
      if (this.activityStatus) {
        params.invitedState = this.activityStatus;
      }
      if (this.activityDate) {
        params.startTime = this.activityDate[0];
        params.endTime = this.activityDate[1];
      }
      this.doFetch(
        {
          url: "/invited/act-invited/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 创建活动 */
    CreateActivity() {
      this.$router.push({
        path: "/web/CreateActivity",
        query: {
          stu: "add",
        },
      });
    },
    handleStu(validState, invitedId,index) {
      this.$post(
        "/invited/act-invited/changeSalesState",
        {
          invitedId,
          validState,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.getData()
          };
        })
        .catch((err) => {
          console.log(err.data.status);
          if(err.data.status == '-1') {
            this.tableData[index].validState = false;
          }
        });
    },
    /* 活动明细 */
    ActivityDetails(invitedId) {
      this.$router.push({
        path: "/web/ActivityDetails",
        query: {
          invitedId,
        },
      });
    },
    handleEdit(invitedId) {
      this.$router.push({
        path: "/web/CreateActivity",
        query: {
          stu: "edit",
          invitedId,
        },
      });
    },
    /* 删除活动 */
    handleDelete(invitedId) {
      this.doDel(
        {
          url: "/invited/act-invited/remove",
          msg: "你确定要删除吗？删除后,该活动的所有数据均会删除!",
          ps: {
            type: "post",
            data: { invitedId },
          },
        },
        true,
        2
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped></style>
